import { LenkeId, useExternalLinks } from "src/common/lenker";

import { SecondaryLinkButton } from "@components/Button";
import { agreementStatusIsAccept, agreementStatusIsOnHold } from "@features/agreements/avtale.utils";
import { AgreementDetails } from "@model/gen";
import { EditAgreementButtons } from "./EditAgreementButtons/EditAgreementButtons";

import "./AgreementDetailsHeader.scss";


interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementDetailsHeaderButtons = ({ agreementDetails }: Props) => {

    const sendClaimLink = useExternalLinks().getLinkByLinkId(LenkeId.meldSkade)?.href;

    const showClaimLink =
        sendClaimLink && !agreementStatusIsAccept(agreementDetails) && !agreementStatusIsOnHold(agreementDetails);
    return (
                    <div className="agreement-details__header__content__info__buttons">
                        {showClaimLink && (
                            <SecondaryLinkButton to={sendClaimLink} target="_blank" density={"comfortable"}>
                                Meld skade
                            </SecondaryLinkButton>
                        )}

                        <EditAgreementButtons agreementDetails={agreementDetails} />
                    </div>
               
    );
};
