import { Flag } from "./featureflag.model";

export const config: Record<Flag, boolean> = {
    [Flag.FEILSIDE_OPPGRADERING_TILGANGSBASEN_NEDE]: false,
    [Flag.FEILSIDE_OPPGRADERING_TILGANGSBASEN]: false,
    [Flag.KMSTAND_KJORELENGDE]: false,
    [Flag.ENABLE_KJOP]: true,
    [Flag.YRKESSKADE]: true,
    [Flag.NEW_CONSENT]: false,
    [Flag.TILHENGER]: true
};
