import { AgreementDetails } from "@model/gen";
import { AvtaleProduktID } from "@features/agreements/avtale.model";

interface Props {   
    agreementDetails: AgreementDetails
    children: React.ReactNode;
}


export function CarAgreementDetailsWrapper({ agreementDetails, children }: Props) {

    if (agreementDetails.productCode !==  AvtaleProduktID.BIL_NAERING ) {
        return null;
    }

    return ( <section className={"grid grid-cols-1 md:grid-cols-2 gap-40"} >
                {children}
            </section>
    );
}


