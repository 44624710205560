import { Typography } from "@components/Typography";
import type { AgreementDetails } from "@model/gen";
import { AgreementCoverageList } from "./AgreementCoverageList";


interface CarAgreementCoveragesSectionProps {
    agreement: AgreementDetails;
}

export const CarAgreementCoveragesSection: React.FC<CarAgreementCoveragesSectionProps> = ({ agreement }) => {

    return (
        <section className="agreement-details__coverages">
            <Typography className="jkl-spacing-l--bottom" variant="heading-2">
                Dekning
            </Typography>
            <AgreementCoverageList objects={agreement.objects} />
        </section>
    );
};
