import { SideInnhold } from "@components/Side";
import { useTasks } from "@features/tasks/tasks.queries";
import type { AgreementDetails as IAgreementDetails } from "@model/gen";

import { AgreementRenewal, AgreementTasksSection } from "../../components";
import { SmartDelayMessage, TravelCardSection } from "../../components/AgreementSpecificComponents";
import { AgreementClaimsSection } from "../components/AgreementClaimsSection/AgreementClaimsSection";
import { CarAgreementCoveragesSection } from "../components/AgreementSpecific/v05/AgreementCoveragesV05/CarAgreementCoveragesSection";
import { CarAgreementDetailSection } from "../components/AgreementSpecific/v05/CarAgreementDetailSection/CarAgreementDetailSection";
import { AgreementDetailsHeader } from "../components/AgreementDetailsHeader";
import { AgreementDocumentsList } from "../components/AgreementDocumentsList/AgreementDocumentsList";
import { AgreementObjectList } from "../components/AgreementObjectList/AgreementObjectList";
import { AgreementOnHold } from "../components/AgreementRenewal/AgreementOnHold";
import { CoverageComparisonTable } from "../components/AgreementSpecific/v05/CoverageComparisonTable/CoverageComparisonTable";
import { YrkesskadeFAQ } from "../components/faq/YrkesskadeFAQ";
import { CarAgreementDetailsWrapper } from "../components/CarAgreementDetailsWrapper";
import "./AgreementDetailsPreview.scss";

interface Props {
    agreementDetails: IAgreementDetails;
}

export const NewAgreementDetailsPreview = ({ agreementDetails }: Props) => {
    const tasks = useTasks();
    const filteredTasks = tasks.data?.filter((task) => task.agreementNumber === agreementDetails.agreementId);


    return (
        <>
            <AgreementDetailsHeader agreementDetails={agreementDetails} />
            <SideInnhold className="agreement-details__content">
                <AgreementOnHold agreementDetails={agreementDetails} />
                <AgreementRenewal agreementDetails={agreementDetails} />
                <AgreementClaimsSection agreementDetails={agreementDetails} />
                <SmartDelayMessage agreementDetails={agreementDetails} />
                <AgreementDocumentsList agreementDetails={agreementDetails} />
                <AgreementObjectList agreementDetails={agreementDetails} />
               <CarAgreementDetailsWrapper agreementDetails={agreementDetails}>
                    <CarAgreementCoveragesSection agreement={agreementDetails} />
                    <CarAgreementDetailSection agreementDetails={agreementDetails} />
                </CarAgreementDetailsWrapper>

                {filteredTasks && filteredTasks.length > 0 && <AgreementTasksSection agreementTasks={filteredTasks} />}
                <TravelCardSection agreementDetails={agreementDetails} />
                <CoverageComparisonTable agreementDetails={agreementDetails} />
                <YrkesskadeFAQ agreementDetails={agreementDetails}/>
            </SideInnhold>
        </>
    );
};
