import { useEffect, useRef, useState } from "react";

import { Trackingkey, track } from "src/tracking";

import { Typography } from "@components/Typography";
import { utils } from "@features/agreements/avtale.module";
import { useBruker } from "@features/bruker/queries";
import { Button } from "@fremtind/jkl-button-react";
import { Menu } from "@fremtind/jkl-menu-react";
import { ContextualMenuItem } from "@fremtind/jkl-contextual-menu-react";
import { ChevronDownIcon } from "@fremtind/jkl-icons-react";
import type { ModalInstance } from "@fremtind/jkl-modal-react";
import type { AgreementDetailObject, AgreementDetails } from "@model/gen";

import { AgreementChangesComposer } from "../../AgreementChanges";
import { GetFormType } from "../../AgreementChanges/GetFormType";
import { GetFormData } from "../../AgreementChanges/forms/cancel/GetFormData";
import { CoverageTable } from "./CoverageTable/CoverageTable";


interface Props {
    object: AgreementDetailObject;
    agreementDetails: AgreementDetails;
    objectNumber: number;
    objectsSum: number;
}

export const AgreementObjectCard = ({ object, agreementDetails, objectNumber, objectsSum }: Props) => {
    const dialogRef = useRef<ModalInstance | null>();
    const { bruker } = useBruker();
    const changable = bruker?.radgiverKontaktInfo?.navn === "KUNDESENTER SMB" && agreementDetails.coreSystem === "NICE";
    const changeAbleAgreementForm = changable ? GetFormType(agreementDetails.productCode) : null;

    const agreementSpecific = changable ? GetFormData(agreementDetails) : null;

    const [formAction, setFormAction] = useState<"cancel" | "change">("cancel");
    const [iconState, setIconState] = useState(false);
    const [objectName, setObjectName] = useState("");

    const handleFormClick = (objectName: string) => {
        setObjectName(objectName);
        dialogRef.current?.show();
    };

    useEffect(() => {
        handleFormClick(objectName);
    }, [formAction, objectName]);

    return ( 
        <div className={"relative p-40 bg-background-container rounded-s shadow-[0_.25rem_.9375rem_#252a311a]"}>
            {changable && (
                <div>
                    {agreementSpecific?.showCancelForm ? (
                        <Menu 
                        initialPlacement="bottom-start"
                            triggerElement={
                                <Button variant="primary" className="float-right" icon={<ChevronDownIcon />}
                                    type="button"
                                    onClickCapture={() => {
                                        setIconState(!iconState);
                                        track({
                                            hendelse: Trackingkey.Knappetrykk,
                                            knappeId: "endre",
                                            dropdown: true
                                        });
                                    }}
                                >
                                    Endre
                                </Button>
                            }
                        >
                            <ContextualMenuItem
                                className="agreement-changes-menu-item"
                                aria-label={`Bestill endring på ${object.name}`}
                                onClick={() => {
                                    handleFormClick(object.name ?? `Objekt ${objectNumber} av ${objectsSum}`);
                                    track({
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "bestill endring"
                                    });

                                    if (formAction === "cancel") {
                                        setFormAction("change");
                                    } else {
                                        dialogRef.current?.show();
                                    }
                                }}
                            >
                                Bestill endring
                            </ContextualMenuItem>
                            <ContextualMenuItem
                                className="agreement-changes-menu-item"
                                aria-label={`Avslutt ${object.name}`}
                                onClick={() => {
                                    handleFormClick(object.name ?? `Objekt ${objectNumber} av ${objectsSum}`);
                                    track({
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "avslutt avtale"
                                    });
                                    if (formAction === "change") {
                                        setFormAction("cancel");
                                    } else {
                                        dialogRef.current?.show();
                                    }
                                }}
                            >
                                Avslutt avtale
                            </ContextualMenuItem>
                        </Menu>
                    ) : (
                        <Button variant="primary" className="float-right"
                            title="Endre"
                            aria-label={`Bestill endring på ${object.name}`}
                            onClick={() => {
                                handleFormClick(object.name ?? `Objekt ${objectNumber} av ${objectsSum}`);
                                track({
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "bestill endring",
                                    dropdown: false
                                });
                                if (formAction === "cancel") {
                                    setFormAction("change");
                                } else {
                                    dialogRef.current?.show();
                                }
                            }}
                        >
                            Bestill endring
                        </Button>
                    )}
                    {changeAbleAgreementForm && (
                        <AgreementChangesComposer
                            chosenObject={objectNumber > 1 ? objectName : ""}
                            formAction={formAction}
                            formType={changeAbleAgreementForm}
                            avtaleDetaljer={agreementDetails}
                            dialogRef={dialogRef}
                        />
                    )}
                </div>
            )}
            <header className={"flex flex-wrap gap-40 items-center justify-between mb-40"}>
                <Typography variant="heading-3">{object.name ?? `Objekt ${objectNumber} av ${objectsSum}`}</Typography>
            </header>

            <dl className={"flex flex-wrap gap-40 mb-40"}>
                {object.fields.map(({ description, value, type }) => (
                    <div key={description} className={"w-fit break-words"}>
                        <dt className="jkl-heading-4 mb-4 hyphens-auto">{description}</dt>
                        <dd>{utils.formatValue(value, type)}</dd>
                    </div>
                ))}
            </dl>

            <CoverageTable coverages={object.coverageTable} />
        </div>
    );
};
