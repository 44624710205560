import { Flag, useFeatureflag } from "src/common/featureflag";

import { http } from "@features/core";
import { useQuery } from "@tanstack/react-query";

export type ProfessionsResponse = {
    naceCode?: string;
    naceCodeDescription?: string;
    professions?: Array<{
        code: string;
        value: string;
    }>;
};

const fetchAvailableProfessions = async () => {
    const res = await http.get<ProfessionsResponse>("kjop/customer/profession");
    return res;
};

export const useAvailableProfessions = () => {
    const isYrkesskadeEnabled = useFeatureflag(Flag.YRKESSKADE);

    return useQuery({
        queryKey: ["kjop-yrkesskade"],
        queryFn: fetchAvailableProfessions,
        enabled: isYrkesskadeEnabled,
        staleTime: 1000 * 60 * 5,
        retry: false
    });
};
