import { Typography } from "@components/Typography";
import type { AgreementDetailObject, AgreementDetails } from "@model/gen";
import styles from "./AgreementObjectCard.module.scss";


interface Props {
    object: AgreementDetailObject;
    agreementDetails: AgreementDetails;
    objectNumber: number;
    objectsSum: number;
    children?: React.ReactNode;
}

export const AgreementObjectCard = ({ object, objectNumber, objectsSum, children }: Props) => {

    return (
        <div className={styles.card + " " + styles.objekt}>
            <header className={styles.cardHeader}>
                <Typography variant="heading-3">{object.name ?? `Objekt ${objectNumber} av ${objectsSum}`}</Typography>
            </header>
            {children}
        </div>
    );
};
