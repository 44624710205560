import { Trackingkey, track } from "src/tracking";

import { Typography } from "@components/Typography";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { Link } from "@fremtind/jkl-core";
import { ExpandablePanel, Expander } from "@fremtind/jkl-expandable-react";
import { List } from "@fremtind/jkl-list-react";
import type { AgreementDetails } from "@model/gen";

interface Props {
    agreementDetails: AgreementDetails;
}

export const YrkesskadeFAQ = ({ agreementDetails }: Props) => {
    const faq = [
        {
            question: "Hva er yrkesskadeforsikring?",
            answer: (
                <>
                    <Typography variant="body">
                        Forsikringen gjelder for ulykke og sykdom som har skjedd i arbeidssammenheng, og som er definert
                        i{" "}
                        <Link
                            target="blank"
                            href={
                                "https://www.sparebank1.no/nb/hallingdal/bedrift/bedriftsforsikring/yrkesskadeforsikring/yrkesskade.html#lov-om-yrkesskadeforsikring"
                            }
                        >
                            {" "}
                            lov om yrkesskadeforsikring
                        </Link>
                        .
                    </Typography>
                    <Typography variant="body">
                        {" "}
                        Det er lovpålagt for arbeidsgivere å ha yrkesskadeforsikring for alle sine ansatte.
                    </Typography>
                </>
            )
        },
        {
            question: "Hvor mye får den ansatte i yrkesskadeerstatning?",
            answer: (
                <Typography variant="body">
                    Erstatningssummene er basert på folketrygdens{" "}
                    <Link
                        href="https://www.sparebank1.no/nb/hallingdal/privat/kundeservice/forsikring/hva-er-g-eller-grunnbelopet-i-folketrygden.html"
                        target="blank"
                    >
                        {" "}
                        grunnbeløp (G)
                    </Link>{" "}
                    og kommer i tillegg til det folketrygden utbetaler. For erstatningssummer,{" "}
                    <Link
                        href="https://www.sparebank1.no/nb/hallingdal/bedrift/bedriftsforsikring/yrkesskadeforsikring/hva-dekker-yrkesskadeforsikring.html"
                        target="blank"
                    >
                        se egne tabeller
                    </Link>
                    .
                </Typography>
            )
        },
        {
            question: "Selvstendig næringsdrivende eller freelancer?",
            answer: (
                <Typography variant="body">
                    Hvis du er selvstendig næringsdrivende eller freelancer er du ikke pålagt å kjøpe
                    yrkesskadeforsikring. Selv om det ikke er pålagt å kjøpe forsikringen er det anbefalt, da du
                    fortsatt kan bli utsatt for yrkesskader.
                </Typography>
            )
        },
        {
            question: "Hvem må ha yrkesskadeforsikring?",
            answer: (
                <>
                    <Typography variant="body" className="font-bold">
                        Hvem er det pålagt å forsikre?
                    </Typography>
                    <List className="px-24 py-8 mb-24">
                        <li>Alle heltids- og deltidsansatte - uansett stillingsprosent</li>
                        <li>Deltakere på arbeidstiltak</li>
                        <li>Lærlinger og skoleelever som er utplassert frivillige og andre som jobber ulønnet</li>
                    </List>
                    <Typography variant="body" className="font-bold">
                        Hvem er det ikke pålagt å forsikre?
                    </Typography>
                    <List className="px-24 py-8">
                        <li>Selvstendig næringsdrivende</li>
                        <li>Eiere av bedriften som ikke er ansatt</li>
                        <li>De som jobber ulønnet</li>
                        <li>
                            For private de som tar enkeltoppdrag for private de som jobber mindre enn 10 timer per uke
                        </li>
                        <li>For private</li>
                    </List>
                    <Typography variant="body" className="mt-12">
                        *private eller private organisasjoner
                    </Typography>
                </>
            )
        },
        {
            question: "Hva slags tilleggsdekninger finnes?",
            answer: (
                <List>
                    <li>Invaliditetsgrad under 15 %</li>
                    <li>Skader til og fra arbeid</li>
                    <li>Økt ménerstatning</li>
                </List>
            )
        },
        {
            question: "Hva dekker yrkesskadeforsikringen?",
            answer: (
                <Typography variant="body">
                    Ansatte får dekket det økonomiske tapet etter en yrkesskade eller yrkessykdom. Hvis ansatte dør som
                    følge av en arbeidsulykke utbetales det erstatning til de etterlatte.{" "}
                    <Link
                        href="https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/yrkesskadeforsikring.html"
                        target="blank"
                    >
                        Les mer om hva forsikringen dekker her
                    </Link>
                    .
                </Typography>
            )
        }
    ];

    const sections = faq.reduce((resultArray: { question: string; answer: JSX.Element }[][], item, index) => {
        const chunkIndex = Math.floor(index / 3);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
    }, []);

    if (AvtaleProduktID.YRKESSKADE !== agreementDetails.productCode) {
        return null;
    }

    return (
        <div>
            <Typography variant="heading-2" className="mb-24">
                Verdt å vite
            </Typography>
            <div className="flex flex-wrap flex-col md:flex-row md:gap-64">
                {sections.map((section, index) => (
                    <div key={index} className="mb-24 flex-1">
                        {section.map((qa) => (
                            <ExpandablePanel variant="stroke" key={qa.question}>
                                <Expander>{qa.question}</Expander>
                                <ExpandablePanel.Content
                                    onClick={() => {
                                        track({
                                            hendelse: Trackingkey.Utvid,
                                            id: qa.question
                                        });
                                    }}
                                >
                                    {qa.answer}
                                </ExpandablePanel.Content>
                            </ExpandablePanel>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
