import { useContext } from "react";

import ThemeContext from "src/context/ThemeContext";
import { IHttpError } from "src/model/http.typer";
import { Distributor } from "src/model/theme.typer";

import { useAgreementList } from "@features/agreements/agreements.queries";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { useIsManaged } from "@features/bruker/queries";
import { http } from "@features/core";
import { useQuery } from "@tanstack/react-query";

import { useCustomerNumberCheck } from "./kjop.utils";
import { useAvailableProfessions } from "./yrkesskade/queries";

export interface Product {
    flowPath: string;
    productCode: string;
}

export type AvailableProduct =
    | {
          products: Array<Product>;
      }
    | {
          error: IHttpError;
      };

export interface GetKjopApi {
    products?: Array<Product>;
    error?: IHttpError;
    flowPath?: string;
    productPath?: string;
}

export const fetchAllProducts = async () => {
    const res = await http.get<Product[]>("kjop/customer/productsuggestions/flow");

    return res;
};

export const useAvailableProducts = () => {
    const hasNiceCustomerNumber = useCustomerNumberCheck();

    const query = useQuery({
        queryKey: ["kjop-products"],
        queryFn: fetchAllProducts,
        staleTime: 1000 * 60 * 30,
        enabled: hasNiceCustomerNumber,
        retry: false
    });

    return {
        products: query.data,
        error: query.error as unknown as IHttpError,
        query,
        isLoading: query.isLoading
    };
};

const V05_FLOW_PATH = "kjop/bil";
const Z01_FLOW_PATH = "kjop/yrkesskade";

export const useProductPath = (productCode: AvtaleProduktID) => {
    const { products } = useAvailableProducts();
    const { distributor } = useContext(ThemeContext);
    const isDNBCustomer = distributor === Distributor.DNB;

    const { data: agreementsData } = useAgreementList();
    const hasYrkesskade = agreementsData?.agreements.some(
        (agreement) => agreement.productCode === AvtaleProduktID.YRKESSKADE
    );

    const { data } = useAvailableProfessions();
    const hasProfessions = !!data?.professions?.length;

    const hasNiceCustomerNumber = useCustomerNumberCheck();
    const isManaged = useIsManaged();

    if (!hasNiceCustomerNumber || isManaged) {
        return undefined;
    }

    // Disse if'ene skal vekk så snart API'et får produktene i responsen fra `flow`
    // backend må bare få ut fingeren 🤠
    if (productCode === AvtaleProduktID.BIL_NAERING) {
        return V05_FLOW_PATH;
    }

    if (productCode === AvtaleProduktID.YRKESSKADE && !isDNBCustomer && hasProfessions && !hasYrkesskade) {
        return Z01_FLOW_PATH;
    }

    if (productCode === AvtaleProduktID.TRAKTOR_ARBEIDSMASKIN_NERING) {
        return "kjop/kjoretoy/arbeidsmaskin";
    }

    if (productCode === AvtaleProduktID.LASTEBIL) {
        return "kjop/kjoretoy/lastebil";
    }

    if (
        productCode === AvtaleProduktID.TILHENGER_NERING &&
        products?.find((product) => product.productCode === AvtaleProduktID.TILHENGER_NERING)
    ) {
        return "kjop/tilhenger";
    }

    if (productCode === AvtaleProduktID.TILHENGER_NERING) {
        return "kjop/kjoretoy/tilhenger";
    }

    const productPath = products?.find((product) => product.productCode === productCode)?.flowPath;
    return productPath;
};
