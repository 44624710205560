
import { Typography } from "@components/Typography";
import {ExpandablePanel, Expander} from "@fremtind/jkl-expandable-react";
import { AgreementCoverage } from "@model/gen";
import "./Coveraages.scss";


type DekningerProps = {
    coverages: AgreementCoverage[] | undefined;
};

export function Coverages({ coverages }:  DekningerProps) {
    if (!coverages) {
        return null;
    }

    return ( <section className="agreement-coverages-expandable">
                <Typography variant="heading-4" className={"mt-24"}>Dekninger</Typography>
                <div className="my-24 agreement-coverages-expandable-list">
                {coverages.map((coverage) => (
                     <ExpandablePanel variant="stroke" key={coverage?.id}>
                    <Expander
                >
                   {coverage?.name}
                </Expander>
                <ExpandablePanel.Content>

                <div>
            <div className="flex flex-row flex-wrap gap-8 items-center">
             <Typography variant="heading-4">
               {coverage?.deductible?.description}
               </Typography>
               <Typography variant="body">
               {coverage?.deductible?.value}
               </Typography>
               </div>

                 </div>
                </ExpandablePanel.Content>
                </ExpandablePanel>
                ))}

        

                </div>
                </section>
    );
}


