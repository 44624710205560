import { Featureflag, Flag, useFeatureflag } from "src/common/featureflag";

import { Typography } from "@components/Typography";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { useIsManaged } from "@features/bruker/queries";
import { HomePageCTA as HomePageCTAV05 } from "@features/kjop/bil";
import { useAvailableProducts } from "@features/kjop/kjop.queries";
import { useCustomerNumberCheck } from "@features/kjop/kjop.utils";
import { HomePageCTA as HomePageCTAY44 } from "@features/kjop/reise";
import { HomePageCTA as HomePageCTAV50 } from "@features/kjop/tilhenger";
import { HomePageCTA as HomePageCTAZ01 } from "@features/kjop/yrkesskade";

export const SelfServiceSection = () => {
    const { products, isLoading } = useAvailableProducts();
    const isKjopEnabled = useFeatureflag(Flag.ENABLE_KJOP);

    const Y44Object = products?.find((product) => product.productCode === AvtaleProduktID.REISE_NERING);
    const V50Object = products?.find((product) => product.productCode === AvtaleProduktID.TILHENGER_NERING);

    // Ikke vis innganger dersom kunden ikke har et gyldig kundenummer i NICE.
    const hasNiceCustomerNumber = useCustomerNumberCheck();

    // Ikke vis innganger for meglede kunder.
    const isManaged = useIsManaged();

    if (!hasNiceCustomerNumber) return null;
    if (isManaged) return null;
    if (isLoading) return null;
    if (!isKjopEnabled) return null;

    return (
        <section className="bm-purchase__selfservice jkl-spacing-64--bottom">
            <Typography variant="heading-2" className="jkl-spacing-l--bottom">
                Kjøp forsikring selv
            </Typography>
            <div className="bm-purchase__selfservice__cards">
                {Y44Object?.flowPath && <HomePageCTAY44 flowPath={Y44Object.flowPath} title="Reiseforsikring" />}
                <HomePageCTAV05 flowPath="/kjop/bil" title="Bilforsikring" />
                <Featureflag flag={Flag.YRKESSKADE}>
                    <HomePageCTAZ01 />
                </Featureflag>
                <Featureflag flag={Flag.TILHENGER}>{V50Object?.flowPath && <HomePageCTAV50 />}</Featureflag>
            </div>
        </section>
    );
};
