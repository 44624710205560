import { Typography } from "@components/Typography";
import type { AgreementDetails } from "@model/gen";
import { ExpandablePanel, Expander } from "@fremtind/jkl-expandable-react";

import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { AgreementObjectCard } from "./AgreementObjectCard/AgreementObjectCard";
import { AgreementObjectInfo } from "./AgreementObjectCard/AgreementObjectInfo";


interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementObjectList = ({ agreementDetails }: Props) => {

    if (agreementDetails.productCode === AvtaleProduktID.BIL_NAERING ) {
        return null;
    }   
    return (
        <section className="agreement-details__groups">
            <Typography variant="heading-2" className="mb-24">
             Yrkesgrupper
            </Typography>

            {agreementDetails?.objects?.length > 4 ? (
                <section className="flex flex-col gap-8">
                { agreementDetails.objects.map((detail, index) => (
                    <ExpandablePanel variant="fill" className="bg-hvit" key={index}>
                            <Expander
                            >
                               <Typography variant="heading-3">{detail?.name}</Typography>
                            </Expander>
                            <ExpandablePanel.Content>
            
                            <div>
                            <AgreementObjectInfo object={detail} />
            
                             </div>
                            </ExpandablePanel.Content>
                            </ExpandablePanel>
                            ))
                        }
                </section>
            ) : (

            <div className={`grid gap-64 ${agreementDetails?.objects?.length > 1 ? "lg:grid-cols-2" : "grid-cols-1"}`}>
                {agreementDetails.objects.map((detail, index, arr) => (
                    <>
                    <AgreementObjectCard
                        key={index}
                        object={detail}
                        agreementDetails={agreementDetails}
                        objectNumber={index + 1}
                        objectsSum={arr.length}>
                            <AgreementObjectInfo object={detail} />
                        </AgreementObjectCard>
                    </>
                ))}
            </div>
            )}
        </section>
    );
};
