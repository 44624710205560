import { useEffect, useRef, useState } from "react";

import { brukerHarTilgang, isRadgiver } from "src/common/rolle";
import { BrukerRolle } from "src/model/bruker.typer";
import { Trackingkey, track } from "src/tracking";

import { AvtaleProduktID, AvtaleProduktIDMedInnmelding } from "@features/agreements/avtale.model";
import { utils } from "@features/agreements/avtale.module";
import { agreementStatusIsOnHold, isLagringsforsikring } from "@features/agreements/avtale.utils";
import { AgreementChangesComposer } from "@features/agreements/components/AgreementChanges";
import { GetFormType } from "@features/agreements/components/AgreementChanges/GetFormType";
import { GetFormData } from "@features/agreements/components/AgreementChanges/forms/cancel/GetFormData";
import { useBruker } from "@features/bruker/queries";
import { MeldInnKjoretoyButton } from "@features/kjoretoy/components";
import { Button } from "@fremtind/jkl-button-react";
import { ContextualMenuItem } from "@fremtind/jkl-contextual-menu-react";
import { ChevronDownIcon } from "@fremtind/jkl-icons-react";
import { Menu, MenuDivider } from "@fremtind/jkl-menu-react";
import { ModalInstance } from "@fremtind/jkl-modal-react";
import type { AgreementDetails } from "@model/gen";

import { ChangeMileageButton } from "./ChangeMileageButton";

interface AgreementButtonsProps {
    agreementDetails: AgreementDetails;
}

export const EditAgreementButtons = ({ agreementDetails }: AgreementButtonsProps) => {
    const { bruker } = useBruker();
    const canChangeSickPay = brukerHarTilgang(bruker, BrukerRolle.PERSONAL) || isRadgiver(bruker);

    const productId = utils.asAvtaleProduktId(agreementDetails.productCode);
    const isSykelonn = productId === AvtaleProduktID.SYKELONN;
    const isNumberBased = agreementDetails.groupInsuranceType === "NUMBER_BASED";
    const isNameBased = agreementDetails.groupInsuranceType === "NAME_BASED";
    const notInAcceptancePhase = agreementDetails.status !== "ACCEPT";
    const changeNumberBasedAgreement = agreementDetails.changeable && isNumberBased;
    const hasEmployeeAdministration = Object.values(AvtaleProduktIDMedInnmelding).includes(productId);
    const isFleetAgreement = utils.isFlateAvtale(productId);

    const changeEmployeeAmountButton = changeNumberBasedAgreement && notInAcceptancePhase;
    const changeEmployeesButton = hasEmployeeAdministration && isNameBased && notInAcceptancePhase;
    const sickPayButton = isSykelonn && canChangeSickPay;
    const fleetButton = isFleetAgreement;

    const showChangeMileageButton =
        agreementDetails.productCode === AvtaleProduktID.BIL_NAERING &&
        !agreementStatusIsOnHold(agreementDetails) &&
        !isLagringsforsikring(agreementDetails);

    const dialogRef = useRef<ModalInstance | null>();
    const [formAction, setFormAction] = useState<"cancel" | "change">("cancel");
    const changable = bruker?.radgiverKontaktInfo?.navn === "KUNDESENTER SMB" && agreementDetails.coreSystem === "NICE";
    const changeAbleAgreementForm = changable ? GetFormType(agreementDetails.productCode) : null;
    const agreementSpecific = changable ? GetFormData(agreementDetails) : null;

    const showButtons =
        changeEmployeesButton ||
        changeEmployeeAmountButton ||
        sickPayButton ||
        fleetButton ||
        showChangeMileageButton ||
        changable;

    const handleFormClick = () => {
        dialogRef.current?.show();
    };

    useEffect(() => {
        handleFormClick();
    }, [formAction]);

    if (!showButtons) return null;

    return (
        <div className="empty:hidden">
            {showChangeMileageButton ? (
                <ChangeMileageButton agreementDetails={agreementDetails} />
            ) : (
                <Menu
                    className={"agreement-changes-menu"}
                    initialPlacement="bottom-start"
                    onClickCapture={() => {
                        track({
                            hendelse: Trackingkey.Knappetrykk,
                            knappeId: "endre",
                            dropdown: true
                        });
                    }}
                    triggerElement={
                        <Button variant="secondary" iconRight={<ChevronDownIcon bold />}>
                            Endre
                        </Button>
                    }
                >
                    {changeEmployeeAmountButton && (
                        <ContextualMenuItem
                            as="a"
                            href={`/bedrift/ansatte-antall/endre-antall/${agreementDetails.id}`}
                            data-testautoid="detaljer-endre-antall-ansatte-knapp"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "ansatte-antall-endre-antall-knapp"
                                });
                            }}
                        >
                            Endre antall ansatte
                        </ContextualMenuItem>
                    )}

                    {changable && (
                        <>
                            <ContextualMenuItem
                                aria-label={`Bestill endring på via rådgiver`}
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "bestill endring via rådgiver"
                                    });

                                    if (formAction === "cancel") {
                                        setFormAction("change");
                                        handleFormClick();
                                    } else {
                                        dialogRef.current?.show();
                                    }
                                }}
                            >
                                Bestill endring via rådgiver
                            </ContextualMenuItem>

                            {agreementSpecific?.showCancelForm && (
                                <>
                                    <MenuDivider />
                                    <ContextualMenuItem
                                        aria-label={`Avslutt avtale`}
                                        onClick={() => {
                                            track({
                                                hendelse: Trackingkey.Knappetrykk,
                                                knappeId: "avslutt avtale"
                                            });
                                            if (formAction === "change") {
                                                setFormAction("cancel");
                                                handleFormClick();
                                            } else {
                                                dialogRef.current?.show();
                                            }
                                        }}
                                    >
                                        Avslutt avtale
                                    </ContextualMenuItem>
                                </>
                            )}
                        </>
                    )}
                    {changeEmployeesButton && (
                        <ContextualMenuItem
                            as="a"
                            href={`/bedrift/ansatte-navn`}
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "ansatte-meld-inn-ut-knapp"
                                });
                            }}
                            data-testautoid="detaljer-meld-inn-knapp"
                        >
                            Meld inn/ut ansatte
                        </ContextualMenuItem>
                    )}

                    {sickPayButton && (
                        <ContextualMenuItem
                            as="a"
                            href={`/bedrift/ansatte-navn/endre-sykelonn`}
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: "endre-sykelønn-oversikt"
                                });
                            }}
                            data-testautoid="detaljer-endre-sykelonn-knapp"
                        >
                            Endre lønn på Sykelønn
                        </ContextualMenuItem>
                    )}

                    {fleetButton && (
                        <>
                            <ContextualMenuItem
                                as="a"
                                href={`/bedrift/forsikringer/kjoretoy?avtalenummer=${agreementDetails.agreementId}`}
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "endre-flate-oversikt"
                                    });
                                }}
                            >
                                Se kjøretøy
                            </ContextualMenuItem>
                            <div className="m-12 mb-24">
                                <MeldInnKjoretoyButton />
                            </div>
                        </>
                    )}
                </Menu>
            )}
            {changeAbleAgreementForm && (
                <AgreementChangesComposer
                    formAction={formAction}
                    formType={changeAbleAgreementForm}
                    avtaleDetaljer={agreementDetails}
                    dialogRef={dialogRef}
                />
            )}
        </div>
    );
};
