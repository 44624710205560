import { isLagringsforsikring } from "@features/agreements/avtale.utils";
import { AgreementDetails } from "@model/gen";
import { AvtaleProduktID } from "@features/agreements/avtale.model";



export const useAgreementDetailsTitle = (agreementDetails: AgreementDetails) => { 
   if (!agreementDetails) return null;

   const hasLagringsforsikring = isLagringsforsikring(agreementDetails);
   const hasLagringsforsikringString = hasLagringsforsikring ? " (Lagringforsikring)" : "";

    if (agreementDetails.productCode === AvtaleProduktID.BIL_NAERING) {
        return `${agreementDetails.objects[0].name} ${hasLagringsforsikringString}`;
    }

    if (agreementDetails.productCode === AvtaleProduktID.YRKESSKADE) {
        return  agreementDetails.objects.length > 0 ? `${agreementDetails.productName}` : agreementDetails.productName;
    };

   return  agreementDetails.productName;
};

export const useAgreementDetailsSubTitle = (agreementDetails: AgreementDetails) => { 
    if (!agreementDetails) return null;
     const hasLagringsforsikring = isLagringsforsikring(agreementDetails);
     const hasLagringsforsikringString = hasLagringsforsikring ? " (Lagringforsikring)" : "";
     const antallAarsverk = agreementDetails?.additionalInfo?.find(
        (info) => info.description === "Antall årsverk"
    );
    
    if (agreementDetails.productCode ===  AvtaleProduktID.BIL_NAERING) {
        return `${agreementDetails.objects[0].name} ${hasLagringsforsikringString}`;
    } 
    if (agreementDetails.productCode === AvtaleProduktID.YRKESSKADE) {
        return `${antallAarsverk?.value} årsverk, ${agreementDetails.objects.length} yrkesgrupper`;
    }
    return null;
 
 };





