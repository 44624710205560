import type React from "react";
import { Typography } from "@components/Typography";
import { InfoCard } from "@fremtind/jkl-card-react";
import { Tag } from "@fremtind/jkl-tag-react";
import type { AgreementCoverage, AgreementField } from "@model/gen";

import "./AgreementCoverageCard.scss";

interface Props {
    description?: string;
    coverage: AgreementCoverage;
}


export const AgreementCoverageCard: React.FC<Props> = ({ description, coverage }) => {
    const { insuranceSum, name, annualPremium, decreasedTerm, deductible, maxAge } = coverage;

    const formatValue = (value: string, type: AgreementField["type"]) => {
        if (type === "CURRENCY") {
            return Number(value).toLocaleString("nb-NO", {
                style: "currency",
                currency: "NOK",
                maximumFractionDigits: 0
            });
        }

        if (type === "GVALUE") {
            return `${Number(value).toLocaleString("nb-NO", { maximumFractionDigits: 1 })} G`;
        }

        if (value === "") {
            return "Ubegrenset";
        }

        return value;
    };

    return (
        <InfoCard className="agreement-details__coverage__card">
            <Typography bold>{name}</Typography>
            {description && <Typography>{description}</Typography>}
            <div className="agreement-details__coverage__card__tags">
                {insuranceSum.value && (
                    <Tag className="agreement-details__coverage__card__tag">
                        Forsikringssum: {formatValue(insuranceSum.value, insuranceSum.type)}
                    </Tag>
                )}
                {annualPremium && (
                    <Tag className="agreement-details__coverage__card__tag">
                        {annualPremium.description}: {formatValue(annualPremium.value, annualPremium.type)}
                    </Tag>
                )}
                {deductible && (
                    <Tag className="agreement-details__coverage__card__tag">
                        {deductible.value === "Ingen egenandel"
                            ? deductible.value
                            : `${deductible.description}: ${formatValue(deductible.value, deductible.type)}`}
                    </Tag>
                )}
                {decreasedTerm && (
                    <Tag className="agreement-details__coverage__card__tag">
                        {decreasedTerm.description}: {formatValue(decreasedTerm.value, decreasedTerm.type)}
                    </Tag>
                )}
                {maxAge && (
                    <Tag className="agreement-details__coverage__card__tag">
                        {maxAge.description}: {formatValue(maxAge.value, maxAge.type)}
                    </Tag>
                )}
            </div>
        </InfoCard>
    );
};
