import { BreadCrumbs } from "@components/BreadCrumbs";
import { CopyToClipboardButton } from "@components/CopyToClipboardButton";
import { Typography } from "@components/Typography";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { formatDate, formatValuta } from "@fremtind/jkl-formatters-util";
import { InfoTag } from "@fremtind/jkl-tag-react";
import { PopupTip } from "@fremtind/jkl-tooltip-react";
import { AgreementDetails } from "@model/gen";

import "./AgreementDetailsHeader.scss";
import { AgreementDetailsHeaderButtons } from "./AgreementDetailsHeaderButtons";
import { useAgreementDetailsSubTitle, useAgreementDetailsTitle } from "./hooks/useAgreementHeading";
import { useBackgroundImage } from "./hooks/useBackgroundImage";

interface Props {
    agreementDetails: AgreementDetails;
    subTitle?: string;
}

export const AgreementDetailsHeader = ({ agreementDetails }: Props) => {
    const yearlyPrice = formatValuta(agreementDetails.annualPremium);
    const trafikkforsikringsavgift = agreementDetails.additionalInfo?.find(
        (o: any) => o.description === "Trafikkforsikringsavgift til staten"
    )?.value;
    const forsikringsPris = agreementDetails.additionalInfo?.find(
        (o: any) => o.description === "Forsikringspris"
    )?.value;
    const agreementPeriod = `${new Date(agreementDetails.startDate).toLocaleDateString("nb-NO", {
        year: "numeric",
        month: "short",
        day: "numeric"
    })} - ${new Date(agreementDetails.endDate).toLocaleDateString("nb-NO", {
        year: "numeric",
        month: "short",
        day: "numeric"
    })}`;

    const showRenewalTag =
        agreementDetails.renewalPhase === "PRE_RENEWAL" || agreementDetails.renewalPhase === "RENEWAL";
    const showNewPremium = agreementDetails.renewalPhase === "RENEWAL" && !!agreementDetails.nextVersion?.annualPremium;
    const renewedStartDate = agreementDetails.nextVersion?.startDate || agreementDetails.endDate;

    return (
        <header className="agreement-details__header mx-auto">
            <BreadCrumbs />
            <div className="relative flex w-full items-center justify-end max-w-[1400px]">
                <div className="sm:absolute sm:left-0 flex-shrink-0 w-full sm:max-w-[490px]">
                    <div className="agreement-details__header__content__info">
                        {showRenewalTag && (
                            <InfoTag className="w-fit">
                                Avtalen din fornyes {formatDate(new Date(renewedStartDate))}
                            </InfoTag>
                        )}

                        <div className="agreement-details__header__content__info__headings">
                            <Typography component="h1" variant="heading-1" className="jkl-spacing-xs--bottom">
                                {useAgreementDetailsTitle(agreementDetails)}
                            </Typography>
                            <Typography
                                variant="body"
                                className="agreement-details__header__content__info__description"
                            >
                                {useAgreementDetailsSubTitle(agreementDetails)}
                            </Typography>
                        </div>
                        <div className="agreement-details__header__content__info__list">
                            <div>
                                <Typography bold>Avtalenummer:</Typography>
                                <CopyToClipboardButton
                                    textToCopy={agreementDetails.agreementId}
                                    actionLabel="Kopier avtalenummer"
                                />
                            </div>
                            <div>
                                <Typography bold>Avtaleperiode:</Typography>
                                <Typography>{agreementPeriod}</Typography>
                            </div>
                            <div>
                                <Typography bold>{`${showNewPremium ? "Nåværende pris:" : "Årlig pris:"}`}</Typography>
                                <Typography>
                                    <span className={"mr-4"}>{yearlyPrice}</span>
                                    {AvtaleProduktID.BIL_NAERING === agreementDetails.productCode && (
                                        <PopupTip
                                            content={`Forsikringspris: ${forsikringsPris} Trafikkforsikringsavgift til staten: ${trafikkforsikringsavgift} kr`}
                                        ></PopupTip>
                                    )}
                                </Typography>
                            </div>
                            {showNewPremium && (
                                <div className="">
                                    <Typography bold>Ny pris:</Typography>
                                    <Typography>
                                        <span className="mr-4">
                                            {formatValuta(agreementDetails.nextVersion?.annualPremium || 0)}
                                        </span>
                                    </Typography>
                                </div>
                            )}
                        </div>
                        <div className="agreement-details__header__content__info__buttons">
                            <AgreementDetailsHeaderButtons agreementDetails={agreementDetails} />
                        </div>
                    </div>
                </div>
                <div className="sm:pl-64">
                    <img
                        src={useBackgroundImage(agreementDetails.productCode)}
                        alt=""
                        className="aspect-[5/3] rounded-lg object-cover h-[660px] hidden w-full max-w-[80vw] min-w-full  sm:block md:max-w-[1000px] "
                    />
                </div>
            </div>
        </header>
    );
};
