import { utils } from "@features/agreements/avtale.module";
import type { AgreementDetailObject } from "@model/gen";
import { Coverages } from "./CoverageTable/Coverages";


interface Props {
    object: AgreementDetailObject;
}

export const AgreementObjectInfo = ({ object }: Props) => {
    return (
            <div className="agreement-object_info">
            <dl className={"flex flex-wrap gap-24 md:gap-40 m-0"}>
                {object.fields.map(({ description, value, type }) => (
                    <div key={description} className={"break-words flex flex-wrap items-center gap-8"}>
                        <dt className="jkl-heading-4 hyphens-auto">{description}</dt>
                        <dd className="margin-0">{utils.formatValue(value, type)}</dd>
                    </div>
                ))}
            </dl>
            <Coverages coverages={object.coverageList} />
            </div>
    );
};
