import { useEffect } from "react";

import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router-dom";

import { useForrigeVerdi } from "../common/hooks";
import { isRadgiver } from "../common/rolle";
import { Bruker } from "../model/bruker.typer";
import { Distributor } from "../model/theme.typer";
import { randomMixpanelName } from "./mixpanel-navn";
import { Trackable, Tracker, Trackingkey } from "./tracking.model";

const MIXPANEL_DISABLED = () => import.meta.env.VITE_MIXPANEL_ENABLED !== "true" && import.meta.env.MODE !== "test";

const isTestEnv = import.meta.env.MODE === "test";

const inkrementerSideVisning = () => {
    if (MIXPANEL_DISABLED()) {
        return;
    }

    mixpanel.people.increment("side_visninger");
};

export type MixpanelBruker = Bruker & { organizations: string[] };

export const TrackerMixpanel: Tracker = {
    init(user: Bruker, debug = false) {
        mixpanel.init(user.trackingDetails.mixpanelProjectKey, {
            debug,
            disable_persistence: MIXPANEL_DISABLED(),
            // default opt out, da cookie modal brukes for opt in
            opt_out_tracking_by_default: true,
            ip: false,
            api_host: "https://api-eu.mixpanel.com"
        });

        mixpanel.register({ source: "fbm" });

        if (MIXPANEL_DISABLED()) {
            mixpanel?.disable();
        }
    },

    track(trackable: Trackable | Trackable[]) {
        const doTrack = (trackable: Trackable) => {
            const { hendelse, ...meta } = trackable;

            if (hendelse === Trackingkey.None || MIXPANEL_DISABLED()) {
                return;
            }

            if (Object.keys(meta).length) {
                if (isTestEnv) {
                    console.log(`Mixpanel called with: ${JSON.stringify(hendelse)}, ${JSON.stringify(meta)}`);
                    return;
                }
                mixpanel.track(hendelse, meta);
            } else {
                if (isTestEnv) {
                    console.log("Mixpanel called with:", hendelse);
                    return;
                }
                mixpanel.track(hendelse);
            }

            if (import.meta.env.DEV === true) {
                console.debug("mixpanel event:", hendelse, meta);
            }
        };

        if (Array.isArray(trackable)) trackable.forEach((t) => doTrack(t));
        else doTrack(trackable);
    },

    register(props: Record<string, any>) {
        if (isTestEnv) {
            console.log("Mixpanel called with:", props);
            return;
        }

        mixpanel.register(props);
    },

    optIn(bruker: MixpanelBruker, distributor: Distributor) {
        if (MIXPANEL_DISABLED()) {
            return;
        }

        mixpanel.opt_in_tracking();

        const whitelistBrukerNokler = ["opprettet", "loggetInn", "bankIdSertifisert"];

        const filtrertBruker = Object.entries(bruker).reduce((akkumulator: Record<string, any>, [nokkel, verdi]) => {
            if (whitelistBrukerNokler.includes(nokkel)) {
                akkumulator[nokkel] = verdi;
            }

            return akkumulator;
        }, {});

        mixpanel.register({
            gjeldendeOrganisasjon: bruker.gjeldendeOrganisasjonsnummer,
            distributor,
            radgiver: isRadgiver(bruker),
            roller: bruker.roller,
            bank: bruker.radgiverKontaktInfo?.arbeidssted
        });

        const navn = randomMixpanelName();
        filtrertBruker.$first_name = navn.fornavn;
        filtrertBruker.$last_name = navn.etternavn;

        mixpanel.identify(bruker.trackingDetails.trackingIdForFodselsnummer);
        mixpanel.people.set_once(filtrertBruker);
        mixpanel.people.set("distributor", distributor);
        mixpanel.people.set("organisasjoner", bruker.antallOrganisasjoner);
        mixpanel.people.set("vindubredde", window.innerWidth);
        mixpanel.people.set("vinduhøyde", window.innerHeight);
        mixpanel.people.set("organisasjonsliste", bruker.organizations);
        mixpanel.people.set("bank", bruker.radgiverKontaktInfo?.arbeidssted);
    },

    optOut() {
        mixpanel.opt_out_tracking();
    },

    useTrackNavigation(active: boolean) {
        const { pathname } = useLocation();

        const fra = useForrigeVerdi(pathname);

        useEffect(() => {
            if (MIXPANEL_DISABLED()) {
                return;
            }

            if (active) {
                this.track({ hendelse: Trackingkey.Sidebytte, til: pathname, fra: fra || "" });
                inkrementerSideVisning();
            }
        }, [pathname, fra, active]);
    },

    trackTime(key: Trackingkey) {
        mixpanel.time_event(key);
    },

    ABTest(testName: string, variantName: string) {
        mixpanel.track("$experiment_started", { "Experiment name": testName, "Variant name": variantName });
    },

    preview(previewName: string) {
        mixpanel.track("$experiment_started", { "Experiment name": "Preview", "Variant name": previewName });
    }
};
