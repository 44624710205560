import { Trackingkey, track } from "src/tracking";

import { NavCard } from "@fremtind/jkl-card-react";

export const HomePageCTA = () => {
    return (
        <NavCard
            className="cta-banner"
            title="Tilhengerforsikring"
            image={{
                src: "/bedrift/static/images/inngangkjop.png",
                alt: "Person på togplatform"
            }}
            href="kjop/tilhenger"
            description="Denne forsikringen er tilpasset både små varehengere og store lastebilhengere som brukes i virksomheten."
            onClick={() => {
                track({
                    hendelse: Trackingkey.LenkeTrykk,
                    lenkeId: "kjop-forsikring-tilhenger"
                });
            }}
        />
    );
};
