import type React from "react";

import { Typography } from "@components/Typography";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { NavCard } from "@fremtind/jkl-card-react";
import { formatDate } from "@fremtind/jkl-formatters-util";
import type { AgreementDetails } from "@model/gen";

import "./AgreementDocumentsList.scss";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementDocumentsList: React.FC<Props> = ({ agreementDetails }) => {
    const { productCode } = agreementDetails;

    const isBilNaering = productCode === AvtaleProduktID.BIL_NAERING;

    const showRenewedDocument =
        agreementDetails.renewalPhase === "RENEWAL" && !!agreementDetails.nextVersion?.annualPremium;

    const renewalStartDate = agreementDetails.nextVersion?.startDate || agreementDetails.endDate;

    return (
        <section className="agreement-details__documents">
            <Typography variant="heading-2" className="agreement-details__documents__heading">
                Dokumenter
            </Typography>
            <ul key="dokument-liste" className="agreement-details__documents__list">
                {showRenewedDocument && (
                    <li>
                        <NavCard
                            tag={{
                                type: "info",
                                text: "Nytt dokument"
                            }}
                            className="agreement-details__documents__list__card"
                            title={`Fornyet avtaledokument`}
                            href={`/bedrift/api/dokumenter/avtale/pdf/${agreementDetails.id}/fornyelse`}
                            target="_blank"
                            aria-label="Åpne avtaledokument"
                            type="document"
                        >
                            Ny avtale gjelder fra {formatDate(new Date(renewalStartDate))}
                        </NavCard>
                    </li>
                )}
                <li>
                    <NavCard
                        title={`Gjeldende avtaledokument`}
                        href={`/bedrift/api/dokumenter/avtale/pdf/${agreementDetails.id}`}
                        target="_blank"
                        aria-label="Åpne avtaledokument"
                        type="document"
                    >
                        Forsikringsbevis og vilkår
                    </NavCard>
                </li>
                <li>
                    <NavCard
                        title={`Alle dokumenter`}
                        href={`dokumenter?avtaleFilter=${agreementDetails.agreementId}`}
                        aria-label="Gå til dokumenter"
                    >
                        Filtrert på denne avtalen
                    </NavCard>
                </li>
                {!isBilNaering && (
                    <li>
                        <NavCard
                            title={`Forsikringsbekreftelse`}
                            href={`dokumenter?&dokumentFilter=Forsikringsbekreftelse&avtaleFilter=${agreementDetails.agreementId}`}
                            aria-label="Gå til dokumenter"
                        >
                            Dokumentasjon på forsikringsavtale
                        </NavCard>
                    </li>
                )}
                <li>
                    <NavCard
                        title={`Alle fakturaer`}
                        href={`faktura?avtalenr=${agreementDetails.agreementId}`}
                        aria-label="Gå til fakturaer"
                    >
                        Fakturaene dine fra Fremtind
                    </NavCard>
                </li>
            </ul>
        </section>
    );
};
